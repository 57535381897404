<template>
  <div class="bg-emerald-50" v-if="!loading">
    <div class="container mx-auto py-16">
      <img class="mx-auto" :src="press_logo" alt="Step to Bulgaria"/>
      <h1 class="text-4xl font-bold text-center">{{ book.title }}</h1>
    </div>
  </div>

  <div class="bg-white" v-if="!loading">
    <div class="container mx-auto bg-white">
      <Breadcrumb/>
    </div>
  </div>

  <section class="bg-white py-6" v-if="!loading">
    <div class="container mx-auto pb-24">
      <div class="lg:grid lg:grid-cols-3 lg:gap-x-12">
        <div class="col-span-1">
          <div class="relative w-full shadow-lg rounded-2xl pb-[150%] bg-gray-100">
            <img :src="book.cover" alt="Book cover" class="absolute inset-0 w-full h-full rounded-2xl object-cover"/>
          </div>
        </div>
        <div class="col-span-2">
          <h2 class="text-2xl font-bold mb-4 max-md:pt-5">Информация за книгата</h2>
          <p v-if="book.annotation" class="mb-4">
            <div v-html="book.annotation"></div>
          </p>
          <p v-if="book.excerpt" class="mb-4">
            <div v-html="book.excerpt"></div>
          </p>
          <p v-if="book.author" class="mb-4"><strong>Автор:</strong> {{ book.author }}</p>
          <p v-if="book.isbn" class="mb-4"><strong>ISBN:</strong> {{ book.isbn }}</p>
          <p v-if="book.pages" class="mb-4"><strong>Страници:</strong> {{ book.pages }}</p>
          <p v-if="book.price" class="mb-4"><strong>Препоръчителна цена:</strong> {{ book.price }}</p>
        </div>
      </div>

      <div class="py-12">
        <vue3-picture-swipe :items="items" :options="options">
          <template #gallery>
            <div class="my-gallery">
              <figure v-for="(item, index) in items" :key="index" class="gallery-thumbnail">
                <a @click.prevent="openGallery(index)">
                  <img :src="item.thumbnail" :alt="item.title"/>
                </a>
              </figure>
            </div>
          </template>
        </vue3-picture-swipe>
      </div>

      <div class="mt-12" v-if="articles.length">
        <h2 class="text-2xl font-bold mb-4">Статии</h2>
        <div v-if="articles.length">
          <div class="grid grid-cols-1 gap-x-8 gap-y-20 lg:grid-cols-2 xl:grid-cols-3">
            <article v-for="article in articles" :key="article.id" class="flex flex-col items-start justify-between">
              <div class="mt-4">
                <h3 class="text-lg font-semibold leading-6 text-gray-900">{{ article.title }}</h3>
                <p class="mt-5 text-sm leading-6 text-gray-600">{{ article.excerpt }}</p>
              </div>
            </article>
          </div>
          <Paginate v-if="articlePagination" :pagination="articlePagination" @page-changed="fetchArticlesByPage"/>
        </div>
        <p v-else>No articles found.</p>
      </div>

      <div class="mt-12" v-if="reviews.length">
        <h2 class="text-2xl font-bold mb-4">Ревюта</h2>
        <div v-if="reviews.length">
          <div class="grid grid-cols-1 gap-x-8 gap-y-20 lg:grid-cols-2 xl:grid-cols-3">
            <article v-for="review in reviews" :key="review.id" class="flex flex-col items-start justify-between">
              <div class="relative w-full shadow-lg rounded-2xl">
                <p class="p-4">{{ review.content }}</p>
              </div>
              <div class="mt-4">
                <h3 class="text-lg font-semibold leading-6 text-gray-900">{{ review.author }}</h3>
                <p class="text-sm leading-6 text-gray-600">{{ review.date }}</p>
              </div>
            </article>
          </div>
          <Paginate v-if="reviewPagination" :pagination="reviewPagination" @page-changed="fetchReviewsByPage"/>
        </div>
        <p v-else>No reviews found.</p>
      </div>
    </div>
  </section>

  <div v-else class="flex justify-center items-center h-screen">
    <p>Loading...</p>
  </div>
</template>


<script setup>
import { computed, ref, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import Breadcrumb from '../../components/common/Breadcrumb.vue';
import Paginate from '../../components/common/Paginate.vue';
import Vue3PictureSwipe from "vue3-picture-swipe";
import 'vue3-picture-swipe/dist/vue3-picture-swipe.css';

const store = useStore();
const route = useRoute();

const loading = ref(true);
const book = computed(() => store.getters['book/book']);
const articles = computed(() => store.getters['book/articles']);
const reviews = computed(() => store.getters['book/reviews']);
const articlePagination = computed(() => store.getters['book/articlePagination']);
const reviewPagination = computed(() => store.getters['book/reviewPagination']);
const press_logo = new URL('../../assets/StepToBulgaria-logo_press.svg', import.meta.url);

const items = ref([]);
const options = { shareEl: false, bgOpacity: 0.9 };

const loadImages = () => {
  const photos = Object.values(book.value.photos);
  items.value = [];

  photos.forEach(photo => {
    const img = new Image();
    img.src = photo.original_url;
    img.onload = () => {
      items.value.push({
        src: photo.original_url,
        thumbnail: photo.original_url, // Replace with a smaller thumbnail URL if available
        w: img.naturalWidth,
        h: img.naturalHeight,
        title: photo.name
      });
    };
  });
};

const fetchBookDetails = async () => {
  try {
    const slug = route.params.slug;
    await store.dispatch('book/fetchBook', slug);
    await store.dispatch('breadcrumbs/updateBreadcrumbs', [
      { text: 'Издателство', link: '/press/books' },
      { text: 'Книги', link: '/press/books' },
      { text: book.value.title, link: null }
    ]);
    loadImages(); // Load images after book details are fetched
  } catch (error) {
    console.error('Error fetching book details:', error);
  }
};

const fetchArticlesByPage = async (page) => {
  try {
    const slug = route.params.slug;
    await store.dispatch('book/fetchBookArticles', { slug, page });
  } catch (error) {
    console.error('Error fetching book articles:', error);
  }
};

const fetchReviewsByPage = async (page) => {
  try {
    const slug = route.params.slug;
    await store.dispatch('book/fetchBookReviews', { slug, page });
  } catch (error) {
    console.error('Error fetching book reviews:', error);
  }
};

onMounted(async () => {
  await fetchBookDetails();
  await fetchArticlesByPage(1);
  await fetchReviewsByPage(1);
  loading.value = false;
});

watch(() => route.params.slug, async () => {
  loading.value = true;
  await fetchBookDetails();
  await fetchArticlesByPage(1);
  await fetchReviewsByPage(1);
  loading.value = false;
});
</script>


<style>
.my-gallery {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  gap: 1rem; /* Gap between items */
}

.gallery-thumbnail {
  flex: 1 1 200px; /* Allow flexibility in resizing with a base of 200px */
  max-width: 200px; /* Prevent items from growing too large */
  overflow: hidden; /* Hide overflow to ensure uniform size */
  margin: 0; /* Reset default margin */
  padding: 0; /* Reset default padding */
  display: flex; /* Enable flexbox */
  align-items: center; /* Center image vertically */
  justify-content: center; /* Center image horizontally */
  filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
}

.gallery-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Cover the figure area */
  border-radius: 1rem;
}

/* Media query for small screens */
@media (max-width: 768px) {
  .gallery-thumbnail {
    flex: 1 1 45%; /* Allow 2 items per row on small screens */
    max-width: none; /* Remove max-width for flexibility */
  }
}

/* Media query for very small screens */
@media (max-width: 480px) {
  .gallery-thumbnail {
    flex: 1 1 45%; /* Stack items in a single column on very small screens */
  }
}
</style>
