<template>
  <footer class="relative w-full py-14 bg-emerald-50">
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="max-w-3xl mx-auto">
        <router-link :to="{name: 'home'}" class="flex justify-center">
          <img :src="logoUrl" class="w-64" alt=""/>
        </router-link>
        <ul class="flex items-center justify-center flex-col gap-7 md:flex-row md:gap-12 py-10 mb-10 border-b border-emerald-200 text-sm">
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 inline-block">
              <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"/>
            </svg>
            <a href="tel:+359878718512" class="ms-2">+ (359) 878-718-512</a>
          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 inline-block">
              <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"/>
            </svg>
            <a href="mailto:nstaneva@steptobulgaria.com" class="ms-2">nstaneva@steptobulgaria.com</a>
          </li>
        </ul>
        <div class="flex space-x-10 justify-center items-center mb-14">
          <a href="https://www.facebook.com/steptobulgaria" target="_blank" class="bg-blue-50 cursor-pointer rounded-md shadow-md shadow-transparent transition-all duration-300 hover:shadow-emerald-500">
            <svg class="" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 92 92" fill="none">
              <rect x="0.138672" width="" height="" rx="15" fill="#EDF4FF"/>
              <path d="M56.4927 48.6403L57.7973 40.3588H49.7611V34.9759C49.7611 32.7114 50.883 30.4987 54.4706 30.4987H58.1756V23.4465C56.018 23.1028 53.8378 22.9168 51.6527 22.8901C45.0385 22.8901 40.7204 26.8626 40.7204 34.0442V40.3588H33.3887V48.6403H40.7204V68.671H49.7611V48.6403H56.4927Z" fill="#337FFF"/>
            </svg>
          </a>
          <a href="https://www.linkedin.com/in/nadia-staneva-8b0b589" target="_blank" class="bg-blue-50 cursor-pointer rounded-md shadow-md shadow-transparent transition-all duration-300 hover:shadow-emerald-500">
            <svg class="cursor-pointer rounded-md shadow-md shadow-transparent transition-all duration-300 hover:shadow-blue-200" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 92 93" fill="none">
              <rect x="0.138672" y="1" width="91.5618" height="91.5618" rx="15" fill="#EEFAFF"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M24.6975 21.5618C22.6561 21.5618 21 23.1674 21 25.1456V68.0091C21 69.9875 22.6563 71.5918 24.6975 71.5918H67.3325C69.3747 71.5918 71.03 69.9873 71.03 68.0086V25.1456C71.03 23.1674 69.3747 21.5618 67.3325 21.5618H24.6975ZM36.2032 40.9068V63.4304H28.7167V40.9068H36.2032ZM36.6967 33.9411C36.6967 36.1025 35.0717 37.8321 32.4615 37.8321L32.4609 37.8319H32.4124C29.8998 37.8319 28.2754 36.1023 28.2754 33.9409C28.2754 31.7304 29.9489 30.0491 32.5111 30.0491C35.0717 30.0491 36.6478 31.7304 36.6967 33.9411ZM47.833 63.4304H40.3471L40.3469 63.4312C40.3469 63.4312 40.4452 43.0205 40.3475 40.9075H47.8336V44.0957C48.8288 42.5613 50.6098 40.3787 54.5808 40.3787C59.5062 40.3787 63.1991 43.598 63.1991 50.516V63.4304H55.7133V51.3822C55.7133 48.354 54.6293 46.2887 51.921 46.2887C49.8524 46.2887 48.6206 47.6815 48.0796 49.0271C47.8819 49.5072 47.833 50.1813 47.833 50.8535V63.4304Z" fill="#006699"/>
            </svg>
          </a>
          <a href="https://www.youtube.com/channel/UCpI6ftB4iIbo3Of4h0ExupA" target="_blank" class="bg-blue-50 cursor-pointer rounded-md shadow-md shadow-transparent transition-all duration-300 hover:shadow-emerald-500">
            <svg class="cursor-pointer rounded-md shadow-md shadow-transparent transition-all duration-300 hover:shadow-red-200" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 92 93" fill="none">
              <rect x="0.138672" y="1" width="91.5618" height="91.5618" rx="15" fill="#FFECE8"/>
              <path d="M71.2471 33.8708C70.6493 31.6234 68.8809 29.8504 66.6309 29.2428C62.5626 28.1523 46.2396 28.1523 46.2396 28.1523C46.2396 28.1523 29.925 28.1523 25.8484 29.2428C23.6067 29.8421 21.8383 31.615 21.2322 33.8708C20.1445 37.9495 20.1445 46.4647 20.1445 46.4647C20.1445 46.4647 20.1445 54.98 21.2322 59.0586C21.83 61.306 23.5984 63.079 25.8484 63.6866C29.925 64.7771 46.2396 64.7771 46.2396 64.7771C46.2396 64.7771 62.5626 64.7771 66.6309 63.6866C68.8726 63.0873 70.641 61.3144 71.2471 59.0586C72.3348 54.98 72.3348 46.4647 72.3348 46.4647C72.3348 46.4647 72.3348 37.9495 71.2471 33.8708Z" fill="#FF3000"/>
              <path d="M41.0256 54.314L54.5838 46.4647L41.0256 38.6154V54.314Z" fill="white"/>
            </svg>
          </a>
        </div>
        <div class="flex flex-col md:flex-row justify-between items-center">
          <span class="text-xs text-center block">© StepToBulgaria {{ year }}, All rights reserved.</span>
          <span class="text-xs text-center inline-block max-md:py-4">
            <a href="https://digiage.bg" target="_blank">
              Made by <img :src="digiAgeLogo" alt="Made By Digi Age Ltd." class="inline-block h-4">
            </a>
          </span>
        </div>
      </div>
    </div>
  </footer>
</template>


<script setup>
const logoUrl = new URL('../../assets/logo_transparent.png', import.meta.url)
const digiAgeLogo = new URL('../../assets/DigiAgeLogo.svg', import.meta.url)

let date = new Date();

const year = date.getFullYear();
</script>
