<template>
  <div class="bg-emerald-50">
    <div class="container mx-auto py-16">
      <h1 class="text-4xl font-bold text-center">{{ page.name }}</h1>
    </div>
  </div>

  <div class="bg-white">
    <div class="container mx-auto bg-white">
      <Breadcrumb/>
    </div>
  </div>

  <section class="bg-white py-6">
    <div class="container mx-auto pb-24">
      <div class="lg:grid lg:grid-cols-4 lg:gap-x-12">
        <div class="col-span-3 order-1 lg:order-2">
          <div class="mx-auto grid grid-cols-1 gap-x-8 gap-y-20 lg:grid-cols-2 xl:grid-cols-3">
            <article v-for="post in blogArticles" :key="post.id" class="flex flex-col items-start justify-between">
              <router-link :to="{name: 'blog-single', params: {slug: post.slug}}">
                <div class="relative w-full shadow-lg rounded-2xl">
                  <img :src="post.thumb" alt="" class="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"/>
                  <div class="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10"/>
                </div>
              </router-link>
              <div class="mt-8 flex items-center gap-x-4 text-xs">
                <time :datetime="post.datetime" class="text-gray-500">{{ post.date }}</time>
              </div>
              <div class="group relative">
                <h3 class="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                  <router-link :to="{name: 'blog-single', params: {slug: post.slug}}">
                    <span class="absolute inset-0"/>
                    {{ post.name }}
                  </router-link>
                </h3>
                <p class="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">{{ post.intro }}</p>
              </div>
            </article>
          </div>

          <Paginate :pagination="pagination" @page-changed="fetchArticlesByPage"/>
        </div>
        <CategoriesAside class="order-2 lg:order-1"/>
      </div>
    </div>
  </section>
</template>

<script setup>
import CategoriesAside from "./CategoriesAside.vue";
import {computed, onMounted, watch} from "vue";
import {useStore} from "vuex";
import Breadcrumb from "../../components/common/Breadcrumb.vue";
import {useRouter, useRoute} from "vue-router";
import Paginate from "../../components/common/Paginate.vue";
import {usePage} from "../../composables/usePage.js";

const store = useStore();
const router = useRouter();
const route = useRoute();
const blogArticles = computed(() => store.getters['blog/articles']);
const pagination = computed(() => store.getters['blog/pagination']);

const {page, fetchPage} = usePage('blog');

const fetchArticles = async (pageNum = 1) => {
  try {
    await store.dispatch('blog/fetchArticles', pageNum);
    if (page.value) {
      await store.dispatch('breadcrumbs/updateBreadcrumbs', [
        {text: page.value.name, link: null}
      ]);
    }
  } catch (error) {
    await router.push({name: 'not-found'});
  }
};

const fetchArticlesByPage = async (pageNum) => {
  if (typeof pageNum === 'object' && pageNum.url) {
    const urlParams = new URLSearchParams(pageNum.url.split('?')[1]);
    pageNum = urlParams.get('page');
  }
  await fetchArticles(pageNum);
};

onMounted(async () => {
  await fetchPage();
  await fetchArticles();
});

watch(route, async () => {
  await fetchPage();
  await fetchArticles();
});
</script>
