<template>
  <Disclosure as="nav" :class="navbarClass" class="bg-white shadow fixed top-0 left-0 right-0 z-50 transition-all duration-300 ease-in-out" v-slot="{ open }">
    <div class="container mx-auto py-1">
      <div :class="flexClass" class="transition-all duration-300 ease-in-out">
        <!-- Left Logo -->
        <div class="flex flex-shrink-0 items-center">
          <router-link :to="{name: 'home'}"><img :class="logoClass" :src="logoUrl" class="transition-all duration-300 ease-in-out" alt="Step To Bulgaria"/></router-link>
        </div>

        <!-- Centered Links -->
        <div class="flex flex-1 justify-center items-center">
          <div class="hidden sm:flex sm:space-x-8">
            <router-link
                v-for="(menuItem, i) in menuItemsDesktop"
                :key="i"
                :to="{ name: menuItem.urlName }"
                class="nav-link"
                :class="isActiveRoute(menuItem.urlName, 'desktop')"
            >
              {{ menuItem.name }}
            </router-link>
            <!--LanguageSwitcher /-->
          </div>
        </div>
        <div class="hidden sm:ml-6 sm:flex sm:items-center">
          <router-link :to="{name: 'contacts'}" class="btn-primary max-lg:hidden lg:block">
            {{ $t('menuContacts') }}
          </router-link>
        </div>
        <div class="relative -mr-2 flex items-center sm:hidden">
          <!--LanguageSwitcher class="relative right-3 -top-1"/-->
          <!-- Mobile menu button -->
          <DisclosureButton class="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
            <span class="absolute -inset-0.5"/>
            <span class="sr-only">Open main menu</span>
            <Bars3Icon v-if="!open" class="block h-8 w-8" aria-hidden="true"/>
            <XMarkIcon v-else class="block h-8 w-8" aria-hidden="true"/>
          </DisclosureButton>
        </div>
      </div>
    </div>
    <DisclosurePanel class="sm:hidden absolute z-20 w-full bg-white top-[4.9rem]">
      <div class="space-y-1 pb-3 pt-2">
        <!-- Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" -->
        <router-link
            v-for="(menuItem, i) in menuItems"
            :key="i"
            :to="{ name: menuItem.urlName }"
            class="nav-link"
            :class="isActiveRoute(menuItem.urlName, 'mobile')"
        >
          {{ menuItem.name }}
        </router-link>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script setup>
import {useStore} from 'vuex';
import {Disclosure, DisclosureButton, DisclosurePanel} from '@headlessui/vue';
import {Bars3Icon, XMarkIcon} from '@heroicons/vue/24/outline';
import {ref, computed, onMounted, onUnmounted} from "vue";
import menuService from "../../services/menuService.js";
import {useRoute, useRouter} from "vue-router";

const scrollTop = ref(0);
const logoUrl = new URL('../../assets/logo.jpg', import.meta.url);
const menuItems = ref([]);
const router = useRouter();
const route = useRoute();
const loading = ref(true);

const updateScroll = () => {
  scrollTop.value = window.scrollY || document.documentElement.scrollTop;
};

const fetchMenuItems = async () => {
  try {
    loading.value = true;
    const response = await menuService.getAllMenuItems();
    menuItems.value = response.data;
  } catch (error) {
    console.error('Error loading menu items:', error);
  } finally {
    loading.value = false;
  }
};

onMounted(fetchMenuItems);
onMounted(() => {
  window.addEventListener('scroll', updateScroll);
});

onUnmounted(() => {
  window.removeEventListener('scroll', updateScroll);
});

const navbarClass = computed(() => {
  return scrollTop.value > 80 ? 'h-16' : 'h-24';
});

const flexClass = computed(() => {
  return scrollTop.value > 80 ? 'flex h-14 items-center' : 'flex h-24 items-center';
});

const logoClass = computed(() => {
  return scrollTop.value > 80 ? 'h-8' : 'h-12';
});

const store = useStore();

const changeLanguage = (language) => {
  localStorage.setItem('language', language);
  store.dispatch('language/setLanguage', language);
  location.reload();
};

const isActiveRoute = (routeName, mobileDesktop) => {
  try {
    const resolvedPath = router.resolve({ name: routeName }).href;
    let isActive = false;

    if (resolvedPath === '/') {
      isActive = route.path === '/';
    } else {
      isActive = route.path.startsWith(new URL(resolvedPath, window.location.origin).pathname);
    }

    return isActive
        ? mobileDesktop === 'mobile'
            ? 'nav-link-active-mobile'
            : 'nav-link-active'
        : '';
  } catch (error) {
    console.error('Error resolving route:', error, 'for routeName:', routeName);
    return '';
  }
};

const menuItemsDesktop = computed(() => {
  return menuItems.value.filter(item => !item.inMobile);
});
</script>

<style scoped>
</style>
