<template>
  <section class="bg-white py-24">
    <div class="container mx-auto">
      <h2 class="text-4xl lg:text-5xl font-bold text-center">{{ $t('newEventsSliderTitle') }}</h2>
      <div class="mx-auto mt-16 grid grid-cols-1 gap-x-8 gap-y-20 lg:grid-cols-3">
        <article v-for="post in latestArticles" :key="post.id" class="flex flex-col items-start justify-between">
          <router-link :to="{name: 'blog-single', params: {slug: post.slug}}">
            <div class="relative w-full shadow-lg rounded-2xl">
              <img :src="post.thumb" alt="" class="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"/>
              <div class="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10"/>
            </div>
          </router-link>
          <div class="mt-8 flex items-center gap-x-4 text-xs">
            <time :datetime="post.datetime" class="text-gray-500">{{ post.date }}</time>
          </div>
          <div class="group relative">
            <h3 class="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
              <router-link :to="{name: 'blog-single', params: {slug: post.slug}}">
                <span class="absolute inset-0"/>
                {{ post.name }}
              </router-link>
            </h3>
            <p class="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">{{ post.intro }}</p>
          </div>
        </article>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const latestArticles = computed(() => store.getters['blog/latestArticles']);

const fetchLatestArticles = async () => {
  try {
    await store.dispatch('blog/fetchLatestArticles');
  } catch (error) {
    console.error('Error fetching latest articles:', error);
  }
};

onMounted(fetchLatestArticles);
</script>