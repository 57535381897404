<template>
  <aside class="lg:block lg:ml-12 order-1 lg:order-2 max-md:pb-16">
    <h2 class="text-xl font-bold">{{ $t('categoriesSideBar') }}</h2>
    <ul class="mt-4 space-y-2">
      <li v-for="category in blogCategories" :key="category.slug">
        <router-link :to="{ name: 'blog-category', params: { slug: category.slug } }" class="text-gray-700 hover:text-gray-900">
          {{ category.name }}
        </router-link>
      </li>
    </ul>
  </aside>
</template>


<script setup>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const blogCategories = computed(() => store.getters['blog/categories']);

onMounted(() => {
  store.dispatch('blog/fetchCategories');
});
</script>
