<template>
  <section class="bg-emerald-50 py-24">
    <div class="container mx-auto">
      <h1 class="text-4xl lg:text-5xl font-bold text-center">{{ $t('testimonialsSliderTitle') }}</h1>
      <div class="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-16 lg:px-6">
        <swiper
            :modules="modules"
            :autoplay="{
            delay: 5000,
            disableOnInteraction: false,
          }"
            navigation
            id="testimonials-slider"
        >
          <swiper-slide v-for="testimonial in randomTestimonials" :key="testimonial.id" class="relative">
            <figure class="max-w-screen-md mx-auto">
              <svg class="h-12 mx-auto mb-3 text-emerald-400" viewBox="0 0 24 27" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
                    fill="currentColor"/>
              </svg>
              <blockquote>
                <p class="text-xl lg:text-2xl max-lg:px-12 font-medium">"{{ testimonial.testimonial }}"</p>
              </blockquote>
              <figcaption class="flex items-center justify-center mt-6 space-x-3">
                <div class="flex items-center divide-x-2 divide-gray-500">
                  <div class="pr-3 font-medium">{{ testimonial.name }}</div>
                  <div class="pl-3 text-sm font-light text-gray-500">{{ testimonial.position }}</div>
                </div>
              </figcaption>
            </figure>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </section>
</template>

<style>
.swiper-button-prev, .swiper-button-next {
  color: #34d399;
}
</style>

<script setup>
import {Swiper, SwiperSlide} from 'swiper/vue';
import {Navigation} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import {useStore} from 'vuex';
import {onMounted, computed} from 'vue';

const store = useStore();
const randomTestimonials = computed(() => store.getters['testimonials/randomTestimonials']);

onMounted(async () => {
  await store.dispatch('testimonials/fetchRandomTestimonials');
});

const modules = [Navigation];
</script>